<template>
  <div class="login-screen sys-screen">
    <div class="center-block">
      <div class="positioning fadeInDown animated">
        <div class="vertical-10p">
          <img
            src="../../../public/static/assets/img/logo.svg"
            class="center-block logo logo-login"
          >
          <div class="text-center ">
            <h1>{{$t('login.registrationConfirmation')}}</h1>
            <p v-if="!serverResponse">
              {{$t('login.giveThanksForRegConf')}}
            </p>
            <div
              v-if="serverResponse"
              class="callout"
              :class="{ 'callout-danger': serverResponse.type === 'error', 'callout-info' : serverResponse.type !== 'error' }"
            >
              <p>{{ serverResponse.message }}</p>
            </div>
            <div>
              <hr class="line-login">
              <router-link :to="{ name: 'security_login' }">
                {{ $t('login.moveToLogin') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import Loader from '../share/Loader'

export default {
  mixins: [
    Loader
  ],
  data () {
    return {
      serverUrl: `security/${this.$route.params.token}/confirmation`,
      serverResponse: null,
      loading: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')

      api.request('core', 'get', this.serverUrl)
        .then((response) => {
          this.toggleLoading()
          const data = response.data
          if (data.error) {
            this.getServerErrorMessage(data)
          }
        })
        .catch(() => {
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.serverResponse = {
            type: 'info',
            message: this.$t('login.accountActive')
          }
        })
    })
  },
  methods: {
    getServerErrorMessage (data) {
      this.serverResponse = {
        type: 'info',
        message: ''
      }
      const errorName = data.error.name
      if (errorName) {
        this.serverResponse.message = errorName === 'FormValidationError'
          ? 'Błędne dane w formularzu.'
          : errorName
      } else {
        this.serverResponse.message = data.error
      }
    }
  }
}
</script>

<style scoped>
    html {
        position: relative;
        min-height: 100%;
    }
    form {
        padding: 20px;
    }
</style>
